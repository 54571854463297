/* RESET DEFAULT STYLES */
* {
  font-weight: inherit;
  font-family: inherit;
  font-style: inherit;
  font-size: 100%;
  border: 0 none;
  outline: 0;
  margin: 0;
  padding: 0;
}
