
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// The warn palette is optional (defaults to red).
$novus-web-warn: mat.define-palette(mat.$red-palette);

$novus-web-success: mat.define-palette(mat.$green-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$novus-web-theme: mat.define-light-theme((
  color: (
    primary: mat.define-palette(mat.$gray-palette, 800),
    accent: mat.define-palette(mat.$blue-palette, 800),
    warn: $novus-web-warn
  )
));

$novus-web-dark-theme: mat.define-dark-theme((
  color: (
    primary: mat.define-palette(mat.$brown-palette, 800),
    accent: mat.define-palette(mat.$teal-palette),
    warn: $novus-web-warn
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($novus-web-theme);

.mat-success {
  background-color: map_get($novus-web-success, A700);
}

.dark {
  @include mat.all-component-themes($novus-web-dark-theme);
}
